/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"english course"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-1ljfsgy --center pt--80" name={"675ptnnpewv"} style={{"marginBottom":0,"paddingBottom":94.125}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13078/568d9247fb7c425f9bb51d76df46b9ef_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column name={"subjuzq13vc"} style={{"paddingTop":12,"marginBottom":0,"paddingBottom":22.515625}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":1093}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":1138}}>
              
              <Title className="title-box ff--3" content={"<span style=\"color: rgb(199, 18, 18);\">Diving course in english language</span>"}>
              </Title>

              <Text className="text-box ff--3" style={{"maxWidth":1057}} content={"<span style=\"color: rgb(0, 0, 0);\">We are able to provide an individual course for you, where the english speeking instructor goes through the theory lessons with you and is present during practice in the pool in case the translation is needed.<br></span>If you are not certain to join the course right away, you can try a test dive in the pool first. The price of the test dive will be then deducted from the price of the course.<br><br>Price: 7<font color=\"rgba(0,0,0,1)\">&nbsp;900 Kč<br><br><br></font>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"mx8ozp71nt"} style={{"paddingTop":0}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":1190}}>
              
              <Text className="text-box text-box--left ff--3" style={{"maxWidth":994}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"5zuu3l3e9hh"} style={{"marginTop":0,"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box ff--3" content={"Přihláška do kurzu"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}